<template>
    <div>
        <card-header title="New Trip" icon="fa-plus"/>

        <card-body>
            <trip-form :trip="newTrip"/>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" :disabled="!newTrip.title || !newTrip.location || !newTrip.start_on || !newTrip.end_on" @click="submit"><i class="fas fa-plus mr-3"></i>New Trip</b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from "@/TIER/components/CardHeader";
    import CardBody from "@/TIER/components/CardBody";
    import TripForm from "@/cards/trips/TripForm";
    import CardFooter from "@/TIER/components/CardFooter";

    import {client as http} from '../../http_client';

    export default {
        props: ['card', 'props'],
        components: {CardFooter, TripForm, CardBody, CardHeader},
        data: function() {
            return {
                newTrip: {
                    trip_logo: ''
                }
            }
        },
        methods: {
            submit() {
                this.$emit('loading', true);
                http.post('/api/trips/', this.newTrip, {force: true}).then(response => {
                    this.$reloadCard('trips');
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            }
        },
        mounted() {
            this.$saveAlert(true, 'Are you done creating this new trip? Unsaved changes will be lost.');
        }
    };
</script>
